<template>
  <b-modal
    :id="'mark-insertion-order-as-paid-modal'"
    :modal-class="'default-modal'"
    scrollable
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >
    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Informar motivo</span>
        </h2>
      </div>
      <div
        class="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="close()"
      >
        <span class="svg-icon svg-icon-1">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
          </svg>
        </span>
      </div>
    </template>

    <div>
      <AlertWraper
        :content="content"
        :type="content_type"
      />
      <label class="form-label fw-semibold text-muted required">Motivo da baixa</label>
      <div>
        <!-- Apenas v-model -->
        <textarea
          class="form-control form-control-solid"
          placeholder="Digite o motivo"
          v-model="form.manual_payment_description"
        ></textarea>
      </div>
      <div class="text-center mt-3">
        <Button
          class="btn btn-primary"
          @click="submit"
          :disabled="!form.manual_payment_description"
          :loading="loader"
        >
          Marcar como pago
      </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'MarkInsertionOrderAsPaidModal',
  props: ['insertionOrder'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      form: {
        manual_payment_description: ''
      },
      loader: false
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
      this.form.manual_payment_description = ''
    },
    async submit () {
      this.loader = true
      this.content = ''
      try {
        await InsertionOrdersService.markAsPaid(this.insertionOrder.id, this.form)
        this.$bvModal.hide('mark-insertion-order-as-paid-modal')
        this.$emit('updated', this.insertionOrder)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
